

.top-right {
    position: absolute;
    top: 20px;
    right: 20px;
}

.info-row,
.components-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.center-solo-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  /* Aligns children vertically in the middle */
    height: 100vh;  /* Full height of the viewport */
    padding: 10px;
}

.components-row {
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;
}

.deposit-container {
    align-items: center;
}

select {
    padding: 10px;
    margin-right: 10px;
}

.proxy-details {
    font-family: "Courier Prime";
}

body, input {
    padding: 10px;
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
}

.deposit-button, .withdraw-button {
    background-color: #0070f3;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.2s;
}

.withdraw-button {
    background-color: #f30055;
}

.refresh-button {
    background-color: rgba(0, 243, 77, 0.75);
    margin: 5px;
}

.deposit-button:hover {
    background-color: #0052cc;
}

.withdraw-button:hover {
    background-color: #cc004e;
}

.coin-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
}


.coin-info {
    border-radius: 8px; /* Rounded Corners */
    padding: 15px; /* Padding inside each coin info box */
    margin-bottom: 15px; /* Space between coin info boxes */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px; /* Or adjust as needed */
}

.title {
    font-size: 20px; /* Or adjust as needed */
    font-weight: bold; /* Bold Text */
    margin-bottom: 20px; /* Space between title and the first coin info box */
}

.toly {
    margin: auto;
}
